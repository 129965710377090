<template>
  <div>
    <v-row style="background: #1A347F;" class="py-4">
      <v-col cols="12" class="pa-0">
        <div
          class="white--text text-center mt-4 mb-0"
          style="font-weight: 700; font-size: 32px;"
        >
          แลกของรางวัลสำเร็จ!
        </div>
      </v-col>
      <v-col cols="12" class="pb-0">
        <div
          class="white--text text-center fn16 mb-0"
          style="font-weight: 600;"
        >
          กรุณาระบุที่อยู่สำหรับจัดส่ง
        </div>
      </v-col>
      <v-col cols="12">
        <v-card
          max-width="374"
          height="223"
          max-height="223"
          style="background: linear-gradient(268.1deg, #D5E4FF 7.54%, #C1D1FF 100.72%);"
        >
          <v-img
            src="@/assets/Teddy_Bear.png"
            class="mx-auto"
            width="331"
            height="223"
            max-width="331"
            max-height="223"
          />
        </v-card>
      </v-col>
      <v-col cols="12" class="text-center pt-0">
        <span class="white--text fn12" style="font-weight: 600;"
          >BI Teddy Bear 6”</span
        >
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" class="fn16">
      <v-row style="color: #1A347F;" class="mt-8">
        <v-col cols="12">
          <div class="mb-2">ข้อมูลของคุณ</div>
          <v-text-field
            v-model="firstname"
            dense
            outlined
            hide-details
            class="mb-4"
            placeholder="ชื่อ"
          />
          <v-text-field
            v-model="lastname"
            dense
            outlined
            hide-details
            class="mb-4"
            placeholder="นามสกุล"
          />
          <v-text-field
            v-model="tel"
            dense
            outlined
            hide-details
            class="mb-4"
            placeholder="เบอร์โทรศัพท์"
          />
        </v-col>
        <v-col cols="12" class="pb-0">
          <div class="mb-2">ที่อยู่สำหรับจัดส่ง</div>
          <v-text-field
            v-model="address1"
            dense
            outlined
            hide-details
            class="mb-4"
            placeholder="ที่อยู่"
          />
          <v-text-field
            v-model="address2"
            dense
            outlined
            hide-details
            class="mb-4"
            placeholder="ที่อยู่"
          />
          <v-text-field
            v-model="city"
            dense
            outlined
            hide-details
            class="mb-4"
            placeholder="ตำบล/แขวง"
          />
          <v-text-field
            v-model="town"
            dense
            outlined
            hide-details
            class="mb-4"
            placeholder="อำเภอ/เขต"
          />
          <v-text-field
            v-model="country"
            dense
            outlined
            hide-details
            class="mb-4"
            placeholder="จังหวัด"
          />
          <v-text-field
            v-model="ZIPCode"
            dense
            outlined
            hide-details
            class="mb-4"
            placeholder="รหัสไปรษณีย์"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="my-4"></v-divider>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay-opacity="0.9"
      max-width="375"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          block
          elevation="2"
          large
          outlined
          x-large
          v-bind="attrs"
          v-on="on"
          class="font-weight-bold rounded-lg"
          style="color: white; background: linear-gradient(268.1deg, #07204B 7.54%, #1A347F 100.72%);"
        >
          ยืนยัน
        </v-btn>
      </template>
      <v-card
        class="rounded-lg pa-4"
        max-height="329"
        height="329"
        max-width="375"
      >
        <div
          class="fn24-weight7 py-4 text-center justify-center"
          style="color: #07204B;"
        >
          กำลังดำเนินการจัดส่ง !
        </div>
        <div class="py-0 px-4 my-4 fn14 text-center">
          เจ้าหน้าที่จะทำการจัดส่งของรางวัลภายใน 7 วันทำการ
          <br />
          ดูรายละเอียดประวัติการแลกของรางวัลของคุณได้ที่ “My Rewards”
        </div>
        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn
            block
            large
            outlined
            x-large
            v-bind="attrs"
            v-on="on"
            class="font-weight-bold rounded-lg mt-8 fn16"
            style="color: white; background: linear-gradient(268.1deg, #07204B 7.54%, #1A347F 100.72%);"
            @click="submit"
          >
            กลับสู่หน้าหลัก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  created() {},
  data() {
    return {
      dialog: false,
      firstname: "",
      lastname: "",
      tel: "",
      address1: "",
      address2: "",
      city: "",
      town: "",
      country: "",
      ZIPCode: "",
    };
  },
  methods: {
    submit() {
      this.$router.push("/");
    },
  },
};
</script>
